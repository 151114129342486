@import "font.scss";
@import "colors.scss";

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: $grey;
}
body,
input,
select,
textarea,
button {
  font-family: "New Rail Alphabet", -apple-system, system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.3px;
}
body.touch {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

#root {
  margin: 0 auto;
  padding-top: env(safe-area-inset-top);
}
a {
  color: #000;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
img {
  display: block;
  width: 100%;
  height: auto;
}
.bold {
  font-weight: 600;
  letter-spacing: 0;
}
.danger {
  color: $danger;
}
.meta {
  color: $meta;
}
.round {
  border-radius: 100%;
}
.align-center {
  text-align: center;
}
.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

/* Media queries */
@media screen and (min-width: 1000px) {
  .TabBar {
    display: none !important;
  }
  .swiper-wrapper {
    transform: none !important;
    height: auto !important;
    padding-right: 10px !important;
  }
  .Container-match .swiper-wrapper {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .LineupFormations {
    padding: 10px !important;
  }
  .StatsItem {
    padding: 0 10px !important;
  }
  .swiper-slide {
    width: 33.3333% !important;
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 400px) {
  .TabBar {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 300px) {
  body {
    font-size: 12px;
  }
  .Headline-h1 {
    font-size: 18px !important;
  }
  .NavigationBar-left,
  .SubHeader,
  .ListItem,
  .MatchlistItem-link {
    padding-left: 14px !important;
  }
  .SubHeader {
    margin-top: 20px !important;
  }
  .SubText {
    margin-top: 8px !important;
    margin-left: 14px !important;
  }
  .TabBar {
    flex-direction: column !important;
  }
  .TabBar-item {
    height: 40px !important;
  }

  .MatchInfo {
    padding-top: 10px !important;
    height: 80px !important;
  }
  .MatchInfo-home-logo,
  .MatchInfo-away-logo {
    width: 25px !important;
    height: 25px !important;
    padding: 4px !important;
  }
  .MatchInfo-state {
    height: 80px !important;
  }
  .MatchInfo-state-text2 {
    top: 15px !important;
  }

  .NavigationBar-match,
  .NavigationBar-match + .NavigationBar-space,
  .ListItem-meta,
  .ListItem-right {
    display: none !important;
  }
}

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background-color: #c9ceea;
  z-index: 1200;
}
.Loading-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: #3f51b5;
}
.Loading-inner1 {
  width: auto;
  animation: mui-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
}
.Loading-inner2 {
  width: auto;
  animation: mui-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1.15s;
}

@keyframes mui-indeterminate1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes mui-indeterminate2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
